import React from 'react'
import Link from 'next/link'
import Router from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faPiggyBank,
  faPowerOff,
  faUser,
  faTable
} from '@fortawesome/free-solid-svg-icons'
import Popover from '@mui/material/Popover'
import Drawer from '@mui/material/Drawer'
import MediaQuery from 'react-responsive'
import { unloadCurrentUser } from '../currentUser/actions'
import TgDrawerContent from '../tgDrawerContent/tgDrawerContent'
import TgNotificationsPreview from '../tgNotificationsPreview/tgNotificationsPreview'
import TgContactUs from '../tgContactUs/tgContactUs'
import fetchPonyfill from 'fetch-ponyfill'
import {
  reportError,
  reportMessage,
  reportComponentDidCatch,
  generateRandomId
} from '../../lib/utils'

import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import animateScrollTo from 'animated-scroll-to'
import styled from 'styled-components'

const { fetch } = fetchPonyfill()

const StyledTgHeader = styled.section`
  header {
    display: flex;
    height: 74px;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
  }

  .headerWrapper {
    position: relative;
    z-index: 1;
  }

  .desktopView {
    display: flex;
  }

  .mobileView {
    display: none;
  }

  .logoWrapper {
    display: inline-flex;
    flex-grow: 1;
    padding-left: 40px;
    align-items: center;
  }

  .tgthLogo {
    height: 65px;
    width: 319px;
  }

  .unloggedLinks,
  .loggedLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  }

  .loggedLinks {
    height: 100%;
  }

  .secondaryButton {
    font-family: 'gotham-rounded-book', sans-serif;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: #0facf3;
    white-space: nowrap;
    cursor: pointer;
  }

  .primaryButton {
    font-family: 'gotham-rounded-book', sans-serif;
    border-radius: 13px;
    border: solid 1px #0facf3;
    background-color: #0facf3;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.2px;
    white-space: nowrap;
    cursor: pointer;
  }

  .unloggedLinks .secondaryButton,
  .loggedLinks .secondaryButton {
    margin: 0 15px;
  }

  .unloggedLinks .primaryButton {
    margin: 0 30px;
  }

  .menuToggler {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 17px;
    left: 10px;
    text-align: right;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
  }

  .menuImage {
    width: 30px;
    height: 30px;
  }

  .menuButtonDivider {
    width: 31px;
    height: 4px;
    background-color: #0facf3;
    margin-bottom: 5px;
    border-radius: 4px;
  }

  .menuButtonHalfDivider {
    width: 15px;
    height: 4px;
    background-color: #0facf3;
    border-radius: 4px;
  }

  .notificationBellWrapper {
    width: 25px;
    height: 100%;
    position: relative;
    margin-right: 40px;
    display: flex;
    cursor: pointer;
  }

  .notificationsLength {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #f44336;
    text-align: center;
    line-height: 20px;
    display: block;
    color: #fff;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 13px;
    right: -9px;
    top: calc(50% - 15px);
    position: absolute;
  }

  .profileLink {
    display: flex;
    align-items: center;
    margin-right: 30px;
    height: 100%;
    cursor: pointer;
  }

  .username {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
  }

  .firstname,
  .lastname {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    font-weight: bold;
    text-align: right;
    color: #333;
    padding-right: 5px;
  }

  .firstname {
    text-transform: capitalize;
  }

  .lastname {
    text-transform: uppercase;
  }

  .profileImageWrapper {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 5px;
    overflow: hidden;
    margin-right: 15px;
    line-height: 40px;
  }

  .arrowDown {
    margin-left: 20px;
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
  }

  @media screen and (max-width: 930px) {
    .loggedLinks .servicesLink,
    .loggedLinks .contactUsLink {
      display: none;
    }
  }

  @media screen and (max-width: 890px) {
    .servicesLink,
    .contactUsLink {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    .notificationBellWrapper {
      margin-right: 20px;
    }

    .profileLink {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .desktopView {
      display: none;
    }

    .mobileView {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
    }

    .tgthLogo {
      width: 200px;
      height: auto;
    }
  }
`
const StyledLoggedMenuContent = styled.section`
  .loggedMenuContent {
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 285px;
  }

  .loggedMenuItem {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 50px;
    cursor: pointer;
    font-size: 17px;
    font-family: gotham-rounded-book, sans-serif;
  }

  .iconWrapper {
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #0facf3;
  }

  .linkContent {
    font-family: gotham-rounded-book, sans-serif;
    line-height: 1.59;
    letter-spacing: normal;
    color: #063f59;
    flex-grow: 1;
    padding: 0 10px 0 25px;
  }

  .logoutButton {
    background-color: rgba(255, 92, 92, 0.1);
    font-family: gotham-rounded-book, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #ff5c5c;
    text-align: center;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }

  .logoutLoading {
    width: 30px;
    height: 30px;
  }
`
const StyledNotificationMenuContent = styled.section`
  .notificationMenuContent {
    width: 400px;
    max-height: 223px;
    padding: 5px 15px;
    background: #fff;
    overflow: scroll;
  }
`

class TgHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openDrawer: false,
      logoutModalAnchorElt: null,
      notificationsModalAnchorElt: null,
      logoutPending: false,
      openContactUsModal: false
    }
  }

  toggleDrawer = (open) => () => {
    if (this.state.openDrawer !== open) {
      this.setState({
        openDrawer: open
      })
    }
  }

  handleCloseDrawer = () => {
    this.setState({
      openDrawer: false
    })
  }

  handleContactUsAction = (event) => {
    event.preventDefault()
    this.handleCloseDrawer()
    if (window && document.getElementById('contactUs')) {
      const options = {
        offset: -74
      }
      animateScrollTo(document.getElementById('contactUs'), options)
    } else {
      this.handleContactUsModalOpen()
    }
  }

  onOpenLoginModalAction = (event) => {
    event.preventDefault()
    this.handleCloseDrawer()
    if (this.props.handleOpenLoginModal) {
      this.props.handleOpenLoginModal()
    }
  }

  onLogoutAction = (event) => {
    event.preventDefault()
    this.handleCloseDrawer()
    this.handleLogout(event)
  }

  onOpenRegisterModalAction = (event) => {
    event.preventDefault()
    this.handleCloseDrawer()
    if (this.props.openRegisterModal) {
      this.props.openRegisterModal()
    }
  }

  handleLogoutModalOpen = (event) => {
    this.setState({ logoutModalAnchorElt: event.currentTarget })
  }

  handleLogoutModalClose = () => {
    this.setState({ logoutModalAnchorElt: null })
  }

  handleNotificationsModalOpen = (event) => {
    this.setState({ notificationsModalAnchorElt: event.currentTarget })
  }

  handleNotificationsModalClose = () => {
    this.setState({ notificationsModalAnchorElt: null })
  }

  onContactUsModalClose = () => {
    this.setState({ openContactUsModal: false })
  }

  handleContactUsModalOpen = () => {
    this.setState({ openContactUsModal: true })
  }

  notifyErrorOccured = () => {
    const message =
      'Une erreur inconnue est survenue, veuillez réessayer plus tard!'

    return toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })
  }

  notifyLogoutSuccess = () => {
    return toast.success('Vous êtes déconnecté!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      draggable: false,
      bodyClassName: 'notifySuccess',
      pauseOnFocusLoss: false
    })
  }

  handleLogout = async (event) => {
    if (this.state.logoutPending) {
      return
    }
    event.preventDefault()
    this.setState({ logoutPending: true, logoutModalAnchorElt: null })
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/auth/logout`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-tgth-trace-id': generateRandomId()
            },
            credentials: 'same-origin'
          }
        )

        const body = await response.json()

        if (response.ok) {
          this.setState({ logoutPending: false })
          this.props.unloadCurrentUser(body)
          Router.push('/home?logoutSuccess=true')
        } else {
          console.error('Problem while login out')
          reportMessage('Problem while login out: ' + body.name)
          this.setState({ logoutPending: false })
          this.notifyErrorOccured()
        }
      } catch (e) {
        console.error('Problem while login out')
        reportError(e)
        this.setState({ logoutPending: false })
        this.notifyErrorOccured()
      }
    }, 500)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidCatch(error, errorInfo) {
    reportComponentDidCatch(error, errorInfo)
  }

  render() {
    return (
      <StyledTgHeader>
        <div className="headerWrapper">
          <header className="desktopView">
            <div className="logoWrapper">
              <Link href="/home">
                <a>
                  <img
                    className="tgthLogo"
                    alt="togethrust"
                    src="/static/img/tgth.png"
                  />
                </a>
              </Link>
            </div>
            {!this.props.user && (
              <div className="unloggedLinks">
                {/* <Link href="/services">
                  <a className="servicesLink secondaryButton hvr-float">
                    Services
                  </a>
                </Link> */}
                <a
                  className="contactUsLink secondaryButton hvr-float"
                  onClick={this.handleContactUsAction}
                >
                  Nous contacter
                </a>
                <a
                  className="primaryButton hvr-float"
                  onClick={this.props.handleOpenLoginModal}
                >
                  Se connecter
                </a>
              </div>
            )}
            {this.props.user && (
              <div className="loggedLinks">
                {/* <Link href="#">
                  <a className="servicesLink secondaryButton hvr-float">
                    Services
                  </a>
                </Link> */}
                <a
                  className="contactUsLink secondaryButton hvr-float"
                  onClick={this.handleContactUsAction}
                >
                  Nous contacter
                </a>
                <a
                  className="notificationBellWrapper hvr-grow"
                  id="notificationBellWrapper"
                  onClick={this.handleNotificationsModalOpen}
                >
                  <img
                    className="bell"
                    width="25"
                    height="25"
                    alt="notifications"
                    src="/static/img/notification.svg"
                  />
                  {this.props.notificationUnreadCounter > 0 && (
                    <span className="notificationsLength">
                      {this.props.notificationUnreadCounter}
                    </span>
                  )}
                </a>
                <a
                  className="profileLink hvr-icon-sink"
                  onClick={this.handleLogoutModalOpen}
                >
                  <span className="username">
                    <span className="firstname">
                      {this.props.user.firstname}
                    </span>
                  </span>
                  <span className="profileImageWrapper">
                    {this.props.user.photo && (
                      <img
                        className="profileImage"
                        src={this.props.user.photo}
                        width="40"
                        height="40"
                        alt=""
                      />
                    )}
                    {!this.props.user.photo && (
                      <span className="profileImage">
                        <FontAwesomeIcon
                          className="hvr-icon"
                          style={{ width: '40px', height: '40px' }}
                          icon={faUser}
                          color="#6c6c6c"
                          size="2x"
                        />
                      </span>
                    )}
                  </span>
                  {!this.state.logoutModalAnchorElt && (
                    <FontAwesomeIcon
                      className="hvr-icon"
                      style={{ width: '20x', height: '20px' }}
                      icon={faAngleDown}
                      color="#6c6c6c"
                      size="2x"
                    />
                  )}
                  {this.state.logoutModalAnchorElt && (
                    <FontAwesomeIcon
                      className="hvr-icon"
                      style={{ width: '20x', height: '20px' }}
                      icon={faAngleUp}
                      color="#6c6c6c"
                      size="2x"
                    />
                  )}
                </a>
                <MediaQuery query="(min-width: 600px)">
                  <Popover
                    id="notificationsMenu"
                    className="notificationsMenuModal"
                    anchorEl={this.state.notificationsModalAnchorElt}
                    open={Boolean(this.state.notificationsModalAnchorElt)}
                    onClose={this.handleNotificationsModalClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <StyledNotificationMenuContent>
                      <div className="notificationMenuContent">
                        <TgNotificationsPreview />
                      </div>
                    </StyledNotificationMenuContent>
                  </Popover>
                  <Popover
                    id="logoutMenu"
                    className="logoutMenuModal"
                    anchorEl={this.state.logoutModalAnchorElt}
                    open={Boolean(this.state.logoutModalAnchorElt)}
                    onClose={this.handleLogoutModalClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <StyledLoggedMenuContent>
                      <div className="loggedMenuContent">
                        <Link href="/profile">
                          <a className="loggedMenuItem hvr-icon-forward">
                            <span className="iconWrapper">
                              <FontAwesomeIcon
                                className="hvr-icon"
                                style={{ width: '21x', height: '21px' }}
                                icon={faUser}
                                size="2x"
                              />
                            </span>
                            <span className="linkContent">Mon profil</span>
                            <FontAwesomeIcon
                              className="hvr-icon"
                              style={{ width: '20x', height: '20px' }}
                              icon={faAngleRight}
                              color="#6c6c6c"
                              size="2x"
                            />
                          </a>
                        </Link>
                        <Link href="/dashboard">
                          <a className="loggedMenuItem hvr-icon-forward">
                            <span className="iconWrapper">
                              <FontAwesomeIcon
                                className="hvr-icon"
                                style={{ width: '21x', height: '21px' }}
                                icon={faTable}
                                size="2x"
                              />
                            </span>
                            <span className="linkContent">Tableau de bord</span>
                            <FontAwesomeIcon
                              className="hvr-icon"
                              style={{ width: '20x', height: '20px' }}
                              icon={faAngleRight}
                              color="#6c6c6c"
                              size="2x"
                            />
                          </a>
                        </Link>
                        <Link href="/list-piggys">
                          <a className="loggedMenuItem hvr-icon-forward">
                            <span className="iconWrapper">
                              <FontAwesomeIcon
                                className="hvr-icon"
                                style={{ width: '21x', height: '21px' }}
                                icon={faPiggyBank}
                                size="2x"
                              />
                            </span>
                            <span className="linkContent">Mes tirelires</span>
                            <FontAwesomeIcon
                              className="hvr-icon"
                              style={{ width: '20x', height: '20px' }}
                              icon={faAngleRight}
                              color="#6c6c6c"
                              size="2x"
                            />
                          </a>
                        </Link>
                        <a
                          className="loggedMenuItem logoutLink hvr-icon-forward"
                          onClick={this.handleLogout}
                        >
                          {!this.state.logoutPending && (
                            <span className="iconWrapper">
                              <FontAwesomeIcon
                                className="hvr-icon"
                                style={{ width: '21x', height: '21px' }}
                                icon={faPowerOff}
                                color="#ff0000"
                                size="2x"
                              />
                            </span>
                          )}
                          {this.state.logoutPending && (
                            <img
                              className="logoutLoading"
                              src="/static/img/loading-red.svg"
                              alt="loading"
                            />
                          )}
                          <span className="linkContent">Me déconnecter</span>
                          <FontAwesomeIcon
                            className="hvr-icon"
                            style={{ width: '20x', height: '20px' }}
                            icon={faAngleRight}
                            size="2x"
                          />
                        </a>
                      </div>
                    </StyledLoggedMenuContent>
                  </Popover>
                </MediaQuery>
              </div>
            )}
          </header>
          <header className="mobileView">
            <a
              className="menuToggler hvr-forward"
              onClick={this.toggleDrawer(!this.state.openDrawer)}
            >
              <div className="menuButtonDivider" />
              <div className="menuButtonDivider" />
              <div className="menuButtonHalfDivider" />
            </a>
            <Link href="/home">
              <a>
                <img
                  className="tgthLogo"
                  alt="togethrust"
                  src="/static/img/tgth.png"
                />
              </a>
            </Link>
          </header>
        </div>
        <Drawer
          anchor="left"
          open={this.state.openDrawer}
          onClose={this.toggleDrawer(false)}
        >
          <TgDrawerContent
            // eslint-disable-next-line react/jsx-handler-names
            handleContactUsAction={this.handleContactUsAction}
            handleOpenLoginModalAction={this.onOpenLoginModalAction}
            handleLogoutAction={this.onLogoutAction}
            logoutPending={this.state.logoutPending}
            handleOpenRegisterModalAction={this.onOpenRegisterModalAction}
          />
        </Drawer>
        {this.state.openContactUsModal && (
          <TgContactUs
            openModal={this.state.openContactUsModal}
            handleModalClose={this.onContactUsModalClose}
          />
        )}
      </StyledTgHeader>
    )
  }
}

TgHeader.propTypes = {}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {
    unloadCurrentUser: (user) => {
      dispatch(unloadCurrentUser(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TgHeader)
