import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import Dialog from '../tgDialogMigrate'
import { toast } from 'react-toastify'
import Router from 'next/router'
import {
  reportError,
  reportMessage,
  reportComponentDidCatch,
  generateRandomId,
  trackCurrentModal
} from '../../lib/utils'
import fetchPonyfill from 'fetch-ponyfill'
import styled from 'styled-components'
const { fetch } = fetchPonyfill()

const StyledTgRegister = styled.section`
  .loginContentWrapper {
    width: 360px;
    height: auto;
  }

  h2 {
    text-align: center;
    padding: 15px;
    position: relative;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #063f59;
  }

  .modalCloseButton {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: #a1a3a6;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }

  .socialLinksWrapper {
    padding: 15px 40px;
    border-top: 3px solid #f0f3f7;
    border-bottom: 3px solid #f0f3f7;
    position: relative;
  }

  .socialLink {
    position: relative;
    text-align: center;
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    letter-spacing: 0.1px;
    color: #fff;
    margin: 15px auto;
    cursor: pointer;
  }

  .facebookLink {
    background-color: #475993;
  }

  .googleLink {
    background-color: #dd4b39;
  }

  .socialIcon {
    position: absolute;
    left: 3px;
    top: 3px;
  }

  .alternativeDivider {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 16px;
    color: #063f59;
    text-align: center;
    width: 100px;
    height: 15px;
    line-height: 15px;
    position: absolute;
    padding: 0 2px;
    left: calc(50% - 45px);
    bottom: -8px;
    background: #fff;
  }

  .loginForm {
    padding: 20px 40px;
  }

  label {
    display: block;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #063f59;
    padding-bottom: 5px;
  }

  input {
    display: block;
    padding: 5px 15px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    color: #063f59;
    height: 40px;
  }

  input:focus {
    border: 1px solid #0facf3;
  }

  .inputWrapper input {
    margin-bottom: 15px;
  }

  .passwordContainer {
    position: relative;
  }

  .passwordContainer input {
    padding-right: 40px;
    margin-bottom: 0;
  }

  .meter,
  .meterFill {
    margin: 5px 0 15px 0;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .meter {
    border-radius: 5px;
  }

  .meterFill {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: width 0.5s ease-in-out, background 0.25s;
  }

  .meterFill[value='0'] {
    width: 0;
  }
  .meterFill[value='1'] {
    background: red;
    width: 25%;
  }

  .meterFill[value='2'] {
    background: orange;
    width: 50%;
  }

  .meterFill[value='3'] {
    background: yellowgreen;
    width: 75%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .meterFill[value='4'] {
    background: green;
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .eye {
    position: absolute;
    right: 8px;
    top: 28px;
    cursor: pointer;
  }

  .passwordRequirements {
    padding: 5px 0;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 12px;
    color: #a9b2be;
  }

  button {
    width: 100%;
    font-family: gotham-rounded-book, sans-serif;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 0 0 15px 0;
    border-radius: 13px;
    background-color: #0facf3;
    border: none;
  }

  .registerLoading {
    width: 30px;
    height: 30px;
  }

  .createAccountLink {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.1px;
    text-align: center;
    color: #063f59;
    white-space: nowrap;
    cursor: pointer;
    display: block;
  }

  .emphasis {
    color: #0facf3;
    font-weight: bold;
    white-space: normal;
  }

  @media screen and (max-width: 500px) {
    .loginContentWrapper {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 400px) {
    .socialLinksWrapper,
    .loginForm {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

class TgRegister extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fullScreen: false,
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      passwordType: 'password',
      meterValue: 0,
      registerPending: false
    }
  }

  onWidthChange = (mq) => {
    if (mq.matches) {
      this.setState({ fullScreen: true })
    } else {
      this.setState({ fullScreen: false })
    }
  }

  updateFullscreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.addListener(this.onWidthChange)
      this.onWidthChange(mq)
    }
  }

  deregisterFullScreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.removeListener(this.onWidthChange)
    }
  }

  notifyEmailSent = () => {
    toast.dismiss()
    return toast.success(
      `Un lien de vérification a été envoyé à ${this.state.email}!`,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        draggable: false,
        bodyClassName: 'notifySuccess',
        pauseOnFocusLoss: false
      }
    )
  }

  notifyErrorOccured = (errorName) => {
    let message
    switch (errorName) {
      case 'InvalidEmail':
        message = 'Votre email est invalide.'
        break
      case 'InsecurePassword':
        message = "Ce mot de passe n'est pas assez sécurisé."
        break
      case 'MissingRequiredArguments':
        message = 'Tous les champs sont obligatoires.'
        break
      case 'UserAlreadyCreated':
        message = 'Cet email est déjà pris, veuillez en utiliser un autre.'
        break
      default:
        message =
          'Une erreur inconnue est survenue, veuillez réessayer plus tard!'
        break
    }

    toast.dismiss()
    return toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })
  }

  getPasswordSecurityLevel = (password) => {
    if (!password) {
      return 0
    }

    let security = 0
    if (password.length >= 7) {
      security++
    }

    if (password.match(/(?=.*[A-ZÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ])/)) {
      security++
    }

    if (password.match(/(?=.*[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ])/)) {
      security++
    }

    if (password.match(/(?=.*\d)/)) {
      security++
    }

    return security
  }

  handleChange = (event) => {
    const newState = Object.assign({}, this.state)
    newState[event.target.name] = event.target.value

    if (event.target.name === 'password') {
      newState.meterValue = this.getPasswordSecurityLevel(event.target.value)
    }

    this.setState(newState)
  }

  handleTogglePasswordVisibility = () => {
    this.setState({
      passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
    })
  }

  handleSubmit = (event) => {
    if (this.state.registerPending) {
      return
    }
    console.log('A new user is registering ' + this.state.email)
    event.preventDefault()
    this.setState({ registerPending: true })
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/user/create-with-validation`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-tgth-trace-id': generateRandomId()
            },
            body: JSON.stringify({
              email: this.state.email,
              password: this.state.password,
              lastname: this.state.lastname,
              firstname: this.state.firstname
            })
          }
        )

        const body = await response.json()

        if (response.ok) {
          console.log('Register pending: ' + this.state.email)
          this.setState({ registerPending: false })
          this.notifyEmailSent()
          this.props.handleModalClose()
        } else {
          console.error('Problem while registering: ' + this.state.email)
          reportMessage('Problem while registering: ' + body.name)
          this.setState({ registerPending: false })
          this.notifyErrorOccured(body.name)
        }
      } catch (e) {
        reportError(e)
        this.setState({ registerPending: false })
        this.notifyEmailSent()
        console.error('Problem while submitting the email')
      }
    }, 500)
  }

  goToFacebookLogin = () => {
    this.props.handleModalClose()
    Router.push('/api/auth/facebook')
  }

  goToGoogleLogin = () => {
    this.props.handleModalClose()
    Router.push('/api/auth/google')
  }

  componentDidMount() {
    trackCurrentModal('register')
    this.updateFullscreenStatus()
  }

  componentWillUnmount() {
    this.deregisterFullScreenStatus()
  }

  componentDidCatch(error, errorInfo) {
    reportComponentDidCatch(error, errorInfo)
  }

  render() {
    return (
      <Dialog
        fullScreen={this.state.fullScreen}
        open={this.props.openModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <StyledTgRegister>
          <div className="loginContentWrapper">
            <h2>
              Je m'inscris
              <a
                className="modalCloseButton"
                onClick={this.props.handleModalClose}
              >
                X
              </a>
            </h2>
            <div className="socialLinksWrapper">
              <a
                onClick={this.goToFacebookLogin}
                className="socialLink facebookLink hvr-forward"
              >
                <span className="socialIcon">
                  <FontAwesomeIcon
                    style={{ width: '30px', height: '30px' }}
                    icon={faFacebook}
                    color="#fff"
                    size="2x"
                  />
                </span>
                via Facebook
              </a>
              <a
                onClick={this.goToGoogleLogin}
                className="socialLink googleLink hvr-forward"
              >
                <span className="socialIcon">
                  <FontAwesomeIcon
                    className="googleIcon"
                    style={{ width: '30px', height: '30px' }}
                    icon={faGoogle}
                    color="#fff"
                    size="2x"
                  />
                </span>
                via Google
              </a>
              <div className="alternativeDivider">Ou par mail</div>
            </div>
            <form className="loginForm" onSubmit={this.handleSubmit}>
              <div className="inputWrapper">
                <label htmlFor="registerFirstname">Prénom</label>
                <input
                  type="text"
                  name="firstname"
                  id="registerFirstname"
                  required
                  value={this.state.firstname}
                  onChange={this.handleChange}
                />
              </div>
              <div className="inputWrapper">
                <label htmlFor="registerLastname">Nom</label>
                <input
                  type="text"
                  name="lastname"
                  id="registerLastname"
                  required
                  value={this.state.lastname}
                  onChange={this.handleChange}
                />
              </div>
              <div className="inputWrapper">
                <label htmlFor="registerEmail">Email</label>
                <input
                  type="email"
                  name="email"
                  id="registerEmail"
                  required
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="inputWrapper passwordContainer">
                <label htmlFor="registerPassword">Mot de passe</label>
                <input
                  type={this.state.passwordType}
                  name="password"
                  id="registerPassword"
                  required
                  value={this.state.password}
                  onChange={this.handleChange}
                  minLength="8"
                />
                {this.state.passwordType === 'password' ? (
                  <a
                    className="eye"
                    onClick={this.handleTogglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="googleIcon"
                      style={{ width: '20px', height: '20px' }}
                      icon={faEye}
                      size="2x"
                    />
                  </a>
                ) : (
                  <a
                    className="eye"
                    onClick={this.handleTogglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="googleIcon"
                      style={{ width: '20px', height: '20px' }}
                      icon={faEyeSlash}
                      size="2x"
                    />
                  </a>
                )}
                <div className="passwordRequirements">
                  Doit contenir au minimum 8 charactères
                </div>
                <div className="meter">
                  <div className="meterFill" value={this.state.meterValue} />
                </div>
              </div>
              <button className="submitButton hvr-forward" type="submit">
                {!this.state.registerPending && <span>S'inscrire</span>}
                {this.state.registerPending && (
                  <img
                    className="registerLoading"
                    src="/static/img/loading.svg"
                    alt="loading"
                  />
                )}
              </button>
              <a
                className="createAccountLink hvr-forward"
                onClick={this.props.handleSwitchToLoginModal}
              >
                Déjà inscrit ? <span className="emphasis">Se connecter</span>
              </a>

              {/* <Link href="/cgu">
                <a className="acceptCguLink">
                  En créant un compte, vous acceptez les
                  <span className="emphasis">
                    {' '}
                    Conditions Générales d'Utilisation.
                  </span>
                  <br />
                </a>
              </Link> */}
            </form>
          </div>
        </StyledTgRegister>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TgRegister)
