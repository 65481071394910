import React from 'react'
import { connect } from 'react-redux'
import Dialog from '../tgDialogMigrate'
import { toast } from 'react-toastify'
import fetchPonyfill from 'fetch-ponyfill'
import {
  reportError,
  reportMessage,
  reportComponentDidCatch,
  generateRandomId,
  trackCurrentModal
} from '../../lib/utils'
import styled from 'styled-components'
const { fetch } = fetchPonyfill()

const StyledTgNewPassword = styled.section`
  .newPasswordWrapper {
    width: 360px;
    height: auto;
  }

  h2 {
    text-align: center;
    padding: 15px;
    position: relative;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #063f59;
    border-bottom: 3px solid #f0f3f7;
  }

  .modalCloseButton {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: #a1a3a6;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }

  .passwordForm {
    padding: 20px 40px;
  }

  label {
    display: block;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #063f59;
    padding-bottom: 5px;
  }

  input {
    display: block;
    padding: 5px 15px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    color: #063f59;
    height: 40px;
  }

  input:focus {
    border: 1px solid #0facf3;
  }

  .inputWrapper input {
    margin-bottom: 15px;
  }

  button {
    width: 100%;
    font-family: gotham-rounded-book, sans-serif;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 0 0 20px 0;
    border-radius: 13px;
    background-color: #0facf3;
    border: none;
  }

  .generateLoading {
    width: 30px;
    height: 30px;
  }

  .actionsLink {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.1px;
    text-align: center;
    color: #063f59;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    margin-bottom: 15px;
  }

  .emphasis {
    color: #0facf3;
    font-weight: bold;
    white-space: nowrap;
  }

  @media screen and (max-width: 500px) {
    .newPasswordWrapper {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 400px) {
    .generateForm {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

class TgNewPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fullScreen: false,
      email: '',
      generatePending: false
    }
  }

  onWidthChange = (mq) => {
    if (mq.matches) {
      this.setState({ fullScreen: true })
    } else {
      this.setState({ fullScreen: false })
    }
  }

  updateFullscreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.addListener(this.onWidthChange)
      this.onWidthChange(mq)
    }
  }

  deregisterFullScreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.removeListener(this.onWidthChange)
    }
  }

  notifyNewPassword = (email) => {
    toast.dismiss()
    return toast.success(`Un nouveau mot de passe a été envoyé à ${email}`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifySuccess',
      pauseOnFocusLoss: false
    })
  }

  notifyErrorOccured = (errorName) => {
    let message
    toast.dismiss()
    switch (errorName) {
      case 'UserNotFound':
        message = 'Utilisateur non reconnu.'
        break
      case 'MissingRequiredArguments':
        message = 'Tous les champs sont obligatoires.'
        break
      default:
        message =
          'Une erreur inconnue est survenue, veuillez réessayer plus tard!'
        break
    }
    return toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })
  }

  handleChange = (event) => {
    const newState = Object.assign({}, this.state)
    newState[event.target.name] = event.target.value
    this.setState(newState)
  }

  handleSubmit = (event) => {
    if (this.state.generatePending) {
      return
    }
    console.log(`User ${this.state.email} is generating a new password`)
    event.preventDefault()
    this.setState({ generatePending: true })
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/auth/generate-password`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-tgth-trace-id': generateRandomId()
            },
            body: JSON.stringify({
              email: this.state.email
            })
          }
        )

        const body = await response.json()

        if (response.ok) {
          console.log(`User ${this.state.email} has a new password`)
          this.setState({ generatePending: false })
          this.notifyNewPassword(this.state.email)
          this.props.handleModalClose()
        } else {
          console.error(
            'Problem while providing new password to ' + this.state.email
          )
          reportMessage(
            'Problem while providing new password to ' +
              this.state.email +
              ' ' +
              body.name
          )
          this.setState({ generatePending: false })
          this.notifyErrorOccured(body.name)
        }
      } catch (e) {
        console.error(
          'Problem while providing new password to ' + this.state.email
        )
        reportError(e)
        this.setState({ generatePending: false })
        this.notifyErrorOccured()
      }
    }, 500)
  }

  componentDidMount() {
    trackCurrentModal('new-password')
    this.updateFullscreenStatus()
  }

  componentWillUnmount() {
    this.deregisterFullScreenStatus()
  }

  componentDidCatch(error, errorInfo) {
    reportComponentDidCatch(error, errorInfo)
  }

  render() {
    return (
      <Dialog
        fullScreen={this.state.fullScreen}
        open={this.props.openModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <StyledTgNewPassword>
          <div className="newPasswordWrapper">
            <h2>
              Mot de passe oublié
              <a
                className="modalCloseButton"
                onClick={this.props.handleModalClose}
              >
                X
              </a>
            </h2>
            <form className="passwordForm" onSubmit={this.handleSubmit}>
              <div className="inputWrapper">
                <label htmlFor="newPassordEmail">Email</label>
                <input
                  type="email"
                  name="email"
                  id="newPassordEmail"
                  required
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <button className="submitButton hvr-forward" type="submit">
                {!this.state.generatePending && (
                  <span>Demander un mot de passe</span>
                )}
                {this.state.generatePending && (
                  <img
                    className="generateLoading"
                    src="/static/img/loading.svg"
                    alt="loading"
                  />
                )}
              </button>
              <a
                className="actionsLink hvr-forward"
                onClick={this.props.handleSwitchToRegisterModal}
              >
                Pas encore inscrit ?{' '}
                <span className="emphasis">Créer un compte</span>
              </a>
              <a
                className="actionsLink hvr-forward"
                onClick={this.props.handleSwitchToLoginModal}
              >
                Déjà inscrit ? <span className="emphasis">Je me connecte</span>
              </a>
            </form>
          </div>
        </StyledTgNewPassword>
      </Dialog>
    )
  }
}

TgNewPassword.propTypes = {}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TgNewPassword)
