import fetchPonyfill from 'fetch-ponyfill'
import {
  generateRandomId,
  isUserMandatoryProfileComplete,
  isUserMandatoryIdentityComplete,
  isExpiredSessionError,
  redirectOnExpiredSession
} from './utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { reportError } from '../api/services/error'
import * as ConfigService from '../api/services/config'

dayjs.extend(utc)
const { fetch } = fetchPonyfill()
const appConfig = ConfigService.getConfig()
const logPrefix = 'Lib - Psp -'

const pspAccountStatus = {
  ACTIVE: {
    value: 'ACTIVE',
    fr: 'Actif'
  },
  INACTIVE: {
    value: 'INACTIVE',
    fr: 'Inactif'
  },
  KYC_REQUIRED: {
    value: 'KYC_REQUIRED',
    fr: 'KYC requis'
  },
  SUSPENDED: {
    value: 'SUSPENDED',
    fr: 'Suspendu'
  }
}

export const pspKycLevel = {
  LEVEL_0: 'LEVEL_0',
  LEVEL_1: 'LEVEL_1',
  LEVEL_2: 'LEVEL_2',
  LEVEL_3: 'LEVEL_3'
}

export const pspMaxWalletBalance = {
  LEVEL_0: 0,
  LEVEL_1: 150,
  LEVEL_2: 10000,
  LEVEL_3: 100000
}

export const pspDocumentStatus = {
  CREATED: {
    value: 'CREATED',
    fr: 'En vérification',
    tgthStatus: 'pending'
  },
  TO_VALIDATE: {
    value: 'TO_VALIDATE',
    fr: 'En vérification',
    tgthStatus: 'pending'
  },
  VALID: {
    value: 'VALID',
    fr: 'Validé',
    tgthStatus: 'valid'
  },
  ACTIVE: {
    value: 'ACTIVE',
    fr: 'Actif',
    tgthStatus: 'active'
  },
  INVALID: {
    value: 'INVALID',
    fr: 'Non valide',
    tgthStatus: 'invalid'
  }
}

export const pspInvalidDocumentStatus = {
  NOT_ACCEPTED: {
    value: 'NOT_ACCEPTED',
    fr: 'Document refusé'
  },
  UNREADABLE: {
    value: 'UNREADABLE',
    fr: 'Document illisible'
  },
  EXPIRED: {
    value: 'EXPIRED',
    fr: 'Document expiré'
  },
  NOT_COMPLIANT: {
    value: 'NOT_COMPLIANT',
    fr: 'Document non conforme aux types de documents acceptés'
  },
  WRONG_OWNER: {
    value: 'WRONG_OWNER',
    fr: 'Le nom figurant sur le document ne correspond pas au nom de votre profil'
  },
  OTHER: {
    value: 'OTHER',
    fr: "Votre document n'a pu être traité"
  }
}

export async function getUserBalance(req) {
  try {
    const endpoint = req ? appConfig.url : window.location.origin
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }

    if (req?.headers?.cookie) {
      headers.cookie = req.headers.cookie
    }
    const response = await fetch(`${endpoint}/api/psp/user/balance`, {
      method: 'GET',
      headers,
      credentials: 'same-origin'
    })
    const body = await response.json()

    if (response.ok) {
      return body
    } else {
      console.error(
        `${logPrefix} getUserBalance failed - Response: ${response}`
      )
      if (isExpiredSessionError(response)) return redirectOnExpiredSession()
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error(`${logPrefix} getUserBalance failed - Error: ${error}`)
    if (isExpiredSessionError(error)) return redirectOnExpiredSession()
  }
}

export async function getTransactions(
  startDate = '',
  endDate = '',
  piggyWalletId = '',
  page = 1
) {
  try {
    const endpoint = window.location.origin
    const url = new URL(`${endpoint}/api/psp/transactions`)

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }

    // dayjs endOf('day') doesn't work with the endDate format
    const params = {
      startDate: startDate ? dayjs(startDate).utc().valueOf() : startDate,
      endDate: endDate
        ? dayjs(
            dayjs(endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          ).valueOf()
        : endDate,
      piggyWalletId,
      page
    }

    url.search = new URLSearchParams(params).toString()

    const response = await fetch(url, {
      method: 'GET',
      headers,
      credentials: 'same-origin'
    })
    const body = await response.json()

    if (response.ok) {
      return body
    } else {
      console.error(
        `${logPrefix} getTransactions - Failed - Response: ${response}`
      )
      if (isExpiredSessionError(response)) return redirectOnExpiredSession()
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error(`${logPrefix} getTransactions - Error: ${error}`)
    if (isExpiredSessionError(error)) return redirectOnExpiredSession()
  }
}

export async function getUserDocuments(req) {
  try {
    const endpoint = req ? appConfig.url : window.location.origin
    const url = new URL(`${endpoint}/api/psp/userdocuments`)

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }
    if (req?.headers?.cookie) {
      headers.cookie = req.headers.cookie
    }

    const response = await fetch(url, {
      method: 'GET',
      headers,
      credentials: 'same-origin'
    })
    const body = await response.json()

    if (response.ok) {
      return getUserDocumentsToObject(body.userDocuments)
    } else {
      console.error(
        `${logPrefix} getUserDocuments - Failed - Response: ${response}`
      )
      if (isExpiredSessionError(response)) return redirectOnExpiredSession()
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error(`${logPrefix} getUserDocuments - Error: ${error}`)
    if (isExpiredSessionError(error)) return redirectOnExpiredSession()
  }
}

function getUserDocumentsToObject(userDocuments) {
  try {
    return userDocuments.reduce((documentObject, document) => {
      documentObject[document.id] = document
      return documentObject
    }, {})
  } catch (error) {
    reportError(error)
    console.error(`${logPrefix} getUserDocumentsToObject - Error: ${error}`)
  }
}

export function getDocumentStatus(documentType, user, userDocuments) {
  let docId
  try {
    if (user && userDocuments) {
      switch (documentType) {
        case 'pspIdentityDocument1':
          docId = user.pspIdentityDocument1?.id
          break
        case 'pspIdentityDocument2':
          docId = user.pspIdentityDocument2?.id
          break
        case 'pspIbanDocument':
          docId = user.pspBankAccount?.ibanId
          break
        default:
          docId = ''
      }
      if (docId) {
        return userDocuments[docId].status
      }
    }
  } catch (error) {
    reportError(error)
    console.error(
      `${logPrefix} getDocumentStatus - Error for document ${documentType} with docId ${docId}: ${error}`
    )
    return ''
  }
}

export function getUserKycStatus(user, documents) {
  /**
   * Check user situation for his profile informations, identity and iban documents
   * @param user (Object)
   * @param documents (Object) - user documents fetched from W-HA api
   * @return userStatus (Object) - contains status for profile, identity and iban
   */
  const userStatus = { profile: '', identity: '', iban: '' }

  try {
    // -- Profile
    userStatus.profile = isUserMandatoryProfileComplete(user)
      ? 'valid'
      : 'invalid'
    // -- Identity
    if (isUserMandatoryIdentityComplete(user)) {
      // Only document 1 is required for KYC 2
      const document1_status = getDocumentStatus(
        'pspIdentityDocument1',
        user,
        documents
      )
      userStatus.identity = pspDocumentStatus[document1_status].tgthStatus
    } else {
      userStatus.identity = 'invalid'
    }
    // -- Iban
    if (user.pspBankAccount?.ibanId) {
      const iban_status = getDocumentStatus('pspIbanDocument', user, documents)
      userStatus.iban = pspDocumentStatus[iban_status].tgthStatus
    } else {
      userStatus.iban = 'invalid'
    }

    return userStatus
  } catch (error) {
    reportError(error)
    console.error(
      `${logPrefix} userStatus - Error: ${error} - userStatus: ${userStatus}`
    )
    return {}
  }
}

export async function getTransactionDetail(transactionId) {
  try {
    const endpoint = window.location.origin
    const url = new URL(`${endpoint}/api/psp/transaction/${transactionId}`)

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }

    const response = await fetch(url, {
      method: 'GET',
      headers,
      credentials: 'same-origin'
    })
    const body = await response.json()

    if (response.ok) {
      return body.transaction
    } else {
      console.error(
        `${logPrefix} getTransactionDetail - Failed - Response: ${response}`
      )
      if (isExpiredSessionError(response)) return redirectOnExpiredSession()
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error(`${logPrefix} getTransactionDetail - Error: ${error}`)
    if (isExpiredSessionError(error)) return redirectOnExpiredSession()
  }
}

export function getMaxWalletBalance(kycLevel) {
  return pspMaxWalletBalance[kycLevel] || 0
}

export function isUserKycComplete(user) {
  return (
    user &&
    (user.pspAccount?.kyc === pspKycLevel.LEVEL_2 ||
      user.pspAccount?.kyc === pspKycLevel.LEVEL_3) &&
    user.pspAccount?.status !== pspAccountStatus.KYC_REQUIRED.value
  )
}

export function isUserKyc3Required(user) {
  return (
    user &&
    user.pspAccount?.kyc === pspKycLevel.LEVEL_2 &&
    user.pspAccount?.status === pspAccountStatus.KYC_REQUIRED.value
  )
}

// TODO: revoir les conditions de KYC pending en vérifiant peut-être le statut des documents côté back
export function isUserKycPending(user) {
  return (
    !!user.pspIdentityDocument1 &&
    user.pspAccount?.kyc !== pspKycLevel.LEVEL_2 &&
    user.pspAccount?.kyc !== pspKycLevel.LEVEL_3
  )
}

export function isUserIbanComplete(user) {
  return (
    user &&
    [pspDocumentStatus.VALID.value, pspDocumentStatus.ACTIVE.value].includes(
      user.pspBankAccount?.status
    )
  )
}

// TODO: revoir les conditions d'Iban pending en vérifiant peut-être le statut de l'iban côté back
export function isUserIbanPending(user) {
  return user && !!user.pspBankAccount
}
