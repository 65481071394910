import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '../tgDialogMigrate'
import fetchPonyfill from 'fetch-ponyfill'
import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Router from 'next/router'
import {
  generateRandomId,
  reportComponentDidCatch,
  reportError,
  reportMessage,
  isUserMandatoryProfileComplete,
  trackCurrentModal
} from '../../lib/utils'
import {
  isUserKyc3Required,
  isUserKycComplete,
  isUserKycPending,
  isUserIbanComplete,
  isUserIbanPending
} from '../../lib/psp'
import * as ConfigService from '../../api/services/config'
import { loadCurrentUser } from '../currentUser/actions'
const { fetch } = fetchPonyfill()

const appConfig = ConfigService.getConfig()

const StyledTgLogin = styled.section`
  .loginContentWrapper {
    width: 360px;
    min-height: 505px;
    height: auto;
    max-height: 600px;
  }

  h2 {
    text-align: center;
    padding: 15px;
    position: relative;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #063f59;
  }

  .modalCloseButton {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: #a1a3a6;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }

  .socialLinksWrapper {
    padding: 15px 40px;
    border-top: 3px solid #f0f3f7;
    border-bottom: 3px solid #f0f3f7;
    position: relative;
  }

  .socialLink {
    position: relative;
    text-align: center;
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    letter-spacing: 0.1px;
    color: #fff;
    margin: 15px auto;
    cursor: pointer;
  }

  .facebookLink {
    background-color: #475993;
  }

  .googleLink {
    background-color: #dd4b39;
  }

  .socialIcon {
    position: absolute;
    left: 3px;
    top: 3px;
  }

  .alternativeDivider {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 16px;
    color: #063f59;
    text-align: center;
    width: 100px;
    height: 15px;
    line-height: 15px;
    position: absolute;
    padding: 0 2px;
    left: calc(50% - 45px);
    bottom: -8px;
    background: #fff;
  }

  .loginForm {
    padding: 20px 40px;
  }

  label {
    display: block;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #063f59;
    padding-bottom: 5px;
  }

  input {
    display: block;
    padding: 5px 15px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    color: #063f59;
    height: 40px;
  }

  input:focus {
    border: 1px solid #0facf3;
  }

  .inputWrapper input {
    margin-bottom: 15px;
  }

  .passwordContainer {
    position: relative;
  }

  .passwordContainer input {
    padding-right: 40px;
  }

  .eye {
    position: absolute;
    right: 8px;
    top: 28px;
    cursor: pointer;
  }

  .passwordForgottenLink {
    margin: 0 auto;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.1px;
    text-align: center;
    color: #0facf3;
    padding: 0 10px 10px 10px;
    display: block;
    cursor: pointer;
  }

  button {
    width: 100%;
    font-family: gotham-rounded-book, sans-serif;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 0 0 15px 0;
    border-radius: 13px;
    background-color: #0facf3;
    border: none;
  }

  .loginLoading {
    width: 30px;
    height: 30px;
  }

  .createAccountLink {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.1px;
    text-align: center;
    color: #063f59;
    white-space: nowrap;
    cursor: pointer;
    display: block;
  }

  .emphasis {
    color: #0facf3;
    font-weight: bold;
    white-space: nowrap;
  }

  @media screen and (max-width: 500px) {
    .loginContentWrapper {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 400px) {
    .socialLinksWrapper,
    .loginForm {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

class TgLogin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fullScreen: false,
      email: '',
      password: '',
      passwordType: 'password',
      loginPending: false
    }
  }

  onWidthChange = (mq) => {
    if (mq.matches) {
      this.setState({ fullScreen: true })
    } else {
      this.setState({ fullScreen: false })
    }
  }

  updateFullscreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.addListener(this.onWidthChange)
      this.onWidthChange(mq)
    }
  }

  deregisterFullScreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.removeListener(this.onWidthChange)
    }
  }

  notifyErrorOccured = (errorName) => {
    let message
    toast.dismiss()
    switch (errorName) {
      case 'InvalidIdentifiers':
        message = 'Ces identifiants sont invalides.'
        break
      case 'MissingRequiredArguments':
        message = 'Tous les champs sont obligatoires.'
        break
      default:
        message =
          'Une erreur inconnue est survenue, veuillez réessayer plus tard!'
        break
    }

    return toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })
  }

  handleChange = (event) => {
    const newState = Object.assign({}, this.state)
    newState[event.target.name] = event.target.value
    this.setState(newState)
  }

  handleTogglePasswordVisibility = () => {
    this.setState({
      passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
    })
  }

  goToFacebookLogin = () => {
    this.props.handleModalClose()
    Router.push('/api/auth/facebook')
  }

  goToGoogleLogin = () => {
    this.props.handleModalClose()
    Router.push('/api/auth/google')
  }

  handleSubmit = (event) => {
    if (this.state.loginPending) {
      return
    }
    console.log('A new user is logging ' + this.state.email)
    event.preventDefault()
    this.setState({ loginPending: true })
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/auth/login`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-tgth-trace-id': generateRandomId()
            },
            body: JSON.stringify({
              email: this.state.email,
              password: this.state.password
            })
          }
        )

        const body = await response.json()
        if (response.ok) {
          this.setState({ loginPending: false })
          if (this.props.redirectPath) {
            Router.push(this.props.redirectPath)
          } else if (!isUserMandatoryProfileComplete(body)) {
            Router.push('/profile?notifyLogin=1')
          } else if (isUserKyc3Required(body)) {
            Router.push(appConfig.profile.kyc3Required)
          } else if (!isUserKycComplete(body) && !isUserKycPending(body)) {
            Router.push('/profile?notifyLogin=1#tab=1')
          } else if (!isUserIbanComplete(body) && !isUserIbanPending(body)) {
            Router.push('/profile?notifyLogin=1#tab=2')
          } else {
            Router.push('/dashboard?notifyLogin=1')
          }
        } else {
          console.error(`Problem while login ${this.state.email}: ${response}`)
          reportMessage(
            `Problem while login ${this.state.email} : ${body.name}`
          )
          this.setState({ loginPending: false })
          this.notifyErrorOccured(body.name)
        }
      } catch (e) {
        console.error(`Error while login ${this.state.email}: ${e}`)
        reportError(e)
        this.setState({ loginPending: false })
        this.notifyErrorOccured()
      }
    }, 500)
  }

  componentDidMount() {
    trackCurrentModal('login')
    this.updateFullscreenStatus()
  }

  componentWillUnmount() {
    this.deregisterFullScreenStatus()
  }

  componentDidCatch(error, errorInfo) {
    reportComponentDidCatch(error, errorInfo)
  }

  render() {
    return (
      <Dialog
        fullScreen={this.state.fullScreen}
        open={this.props.openModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <StyledTgLogin>
          <div className="loginContentWrapper">
            <h2>
              Je me connecte
              <a
                className="modalCloseButton"
                onClick={this.props.handleModalClose}
              >
                X
              </a>
            </h2>
            <div className="socialLinksWrapper">
              <a
                onClick={this.goToFacebookLogin}
                className="socialLink facebookLink hvr-forward"
              >
                <span className="socialIcon">
                  <FontAwesomeIcon
                    style={{ width: '30px', height: '30px' }}
                    icon={faFacebook}
                    color="#fff"
                    size="2x"
                  />
                </span>
                via Facebook
              </a>
              <a
                onClick={this.goToGoogleLogin}
                className="socialLink googleLink hvr-forward"
              >
                <span className="socialIcon">
                  <FontAwesomeIcon
                    className="googleIcon"
                    style={{ width: '30px', height: '30px' }}
                    icon={faGoogle}
                    color="#fff"
                    size="2x"
                  />
                </span>
                via Google
              </a>
              <div className="alternativeDivider">Ou par mail</div>
            </div>
            <form className="loginForm" onSubmit={this.handleSubmit}>
              <div className="inputWrapper">
                <label htmlFor="loginEmail">Email</label>
                <input
                  type="email"
                  name="email"
                  id="loginEmail"
                  required
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="inputWrapper passwordContainer">
                <label htmlFor="loginPassword">Mot de passe</label>
                <input
                  type={this.state.passwordType}
                  name="password"
                  id="loginPassword"
                  required
                  value={this.state.password}
                  onChange={this.handleChange}
                />

                {this.state.passwordType === 'password' ? (
                  <a
                    className="eye"
                    onClick={this.handleTogglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="googleIcon"
                      style={{ width: '20px', height: '20px' }}
                      icon={faEye}
                      size="2x"
                    />
                  </a>
                ) : (
                  <a
                    className="eye"
                    onClick={this.handleTogglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="googleIcon"
                      style={{ width: '20px', height: '20px' }}
                      icon={faEyeSlash}
                      size="2x"
                    />
                  </a>
                )}
              </div>
              <a
                className="passwordForgottenLink hvr-forward"
                onClick={this.props.handleSwitchToNewPasswordModal}
              >
                Mot de passe oublié
              </a>
              <button className="submitButton hvr-forward" type="submit">
                {!this.state.loginPending && <span>Se connecter</span>}
                {this.state.loginPending && (
                  <img
                    className="loginLoading"
                    src="/static/img/loading.svg"
                    alt="loading"
                  />
                )}
              </button>
              <a
                className="createAccountLink hvr-forward"
                onClick={this.props.handleSwitchToRegisterModal}
              >
                Pas encore inscrit ?{' '}
                <span className="emphasis">Créer un compte</span>
              </a>
            </form>
          </div>
        </StyledTgLogin>
      </Dialog>
    )
  }
}

TgLogin.propTypes = {}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {
    loadCurrentUser: (user) => {
      dispatch(loadCurrentUser(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TgLogin)
