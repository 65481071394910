import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faFacebook,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import CookieBanner from '../CookieBanner'
import styled from 'styled-components'

const StyledTgFooter = styled.section`
  .desktopView {
    background-image: linear-gradient(115deg, #0facf3, #11adf3 29%, #9adaf5);
    display: flex;
    justify-content: space-evenly;
    padding: 40px 20px;
    flex-wrap: nowrap;
  }

  .tabletView {
    display: none;
  }

  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .company {
    font-family: gotham-rounded-book;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: #fff;
    padding-bottom: 20px;
    text-transform: uppercase;
  }

  .copyright {
    font-family: gotham-rounded-book;
    font-size: 20px;
    letter-spacing: 0;
    color: #fff;
  }

  .linkListWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: ${({ isLight }) => (isLight ? 'center' : 'space-between')};
  }

  .linkListWrapper a {
    padding: 10px 15px;
    color: #fff;
    font-size: 15px;
  }

  .socialLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    order: 2;
  }

  .socialLinks a {
    padding: 10px;
  }

  @media screen and (max-width: 900px) {
    .tabletView {
      background-image: linear-gradient(115deg, #0facf3, #11adf3 29%, #9adaf5);
      display: flex;
      justify-content: space-evenly;
      padding: 40px 20px;
      flex-wrap: wrap;
    }

    .desktopView {
      display: none;
    }

    .logoAndSocial {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .socialLinks {
      margin-left: 80px;
    }

    .linkListContainer {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin-top: 40px;
    }

    .mobileViewCopyright {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .logoAndSocial {
      flex-direction: column;
    }

    .company {
      font-size: 25px;
      padding-bottom: 15px;
    }

    .copyright {
      display: none;
    }

    .socialLinks {
      margin-left: 0;
    }

    .linkListContainer {
      flex-direction: column;
      margin-top: 20px;
    }

    .linkListWrapper {
      text-align: center;
    }

    .mobileViewCopyright {
      display: block;
      margin-top: 20px;
      text-align: center;
      color: #fff;
      font-size: 15px;
    }
  }
`

class TgFooter extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <StyledTgFooter {...this.props}>
        <CookieBanner />
        <footer className="desktopView">
          <div className="logoWrapper">
            <Link href="/">
              <a className="company hvr-forward">Togethrust</a>
            </Link>
            <div className="copyright">{`© Copyright Togethrust ${new Date().getFullYear()}`}</div>
          </div>
          <div className="linkListWrapper cguWrapper">
            <Link href="/about-us">
              <a className="hvr-forward"> Qui sommes nous ?</a>
            </Link>
            {!this.props.isLight && (
              <>
                <a
                  href="/static/pdf/howto.pdf"
                  target="_blank"
                  className="hvr-forward"
                >
                  Comment ça marche
                </a>
                <a
                  href="https://medium.com/@togethrust"
                  target="_blank"
                  className="hvr-forward"
                  rel="noopener noreferrer"
                >
                  Notre blog
                </a>
                <a
                  href="/static/pdf/cookies-policy.pdf"
                  target="_blank"
                  className="hvr-forward"
                  rel="noopener noreferrer"
                >
                  Politique de cookies
                </a>
              </>
            )}
          </div>
          {!this.props.isLight && (
            <div className="linkListWrapper newsWrapper">
              <a
                href="/static/pdf/data-policy.pdf"
                target="_blank"
                className="hvr-forward"
                rel="noopener noreferrer"
              >
                Protection des données
              </a>
              <a
                href="/static/pdf/cgu.pdf"
                target="_blank"
                className="hvr-forward"
              >
                CGU TOGETHRUST
              </a>
              <a
                href="https://www.api-money.com/cgu/CGU-API-money-EME.pdf"
                target="_blank"
                className="hvr-forward"
              >
                CGU W-HA
              </a>
              <a
                href="/static/pdf/faq.pdf"
                target="_blank"
                className="hvr-forward"
              >
                FAQ
              </a>
              {/* <Link href="#">
                <a className="hvr-forward">Actualités</a>
              </Link>
              <Link href="#">
                <a className="hvr-forward">Ils parlent de nous</a>
              </Link>
              <Link href="#">
                <a className="hvr-forward">Lexique</a>
              </Link>
              <Link href="#">
                <a className="hvr-forward">Mentions légales</a>
              </Link> */}
            </div>
          )}
          <div className="socialLinks">
            <a
              href="https://www.facebook.com/Togethrust-108347978531932"
              className="hvr-forward"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ width: '25px', height: '25px' }}
                icon={faFacebook}
                color="#fff"
                size="2x"
              />
            </a>
            <a
              href="https://www.instagram.com/togethrust_tgth/"
              className="hvr-forward"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ width: '25px', height: '25px' }}
                icon={faInstagram}
                color="#fff"
                size="2x"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/togethrustt/"
              className="hvr-forward"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ width: '25px', height: '25px' }}
                icon={faLinkedin}
                color="#fff"
                size="2x"
              />
            </a>
          </div>
        </footer>
        <footer className="tabletView">
          <div className="logoAndSocial">
            <div className="logoWrapper">
              <Link href="/">
                <a className="company hvr-forward">Togethrust</a>
              </Link>
              <div className="copyright">{`© Copyright Togethrust ${new Date().getFullYear()}`}</div>
            </div>
            <div className="socialLinks">
              <a
                href="https://www.facebook.com/Togethrust-108347978531932"
                className="hvr-forward"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  style={{ width: '25px', height: '25px' }}
                  icon={faFacebook}
                  color="#fff"
                  size="2x"
                />
              </a>
              <a
                href="https://www.instagram.com/togethrust_tgth/"
                className="hvr-forward"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  style={{ width: '25px', height: '25px' }}
                  icon={faInstagram}
                  color="#fff"
                  size="2x"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/togethrustt/"
                className="hvr-forward"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  style={{ width: '25px', height: '25px' }}
                  icon={faLinkedin}
                  color="#fff"
                  size="2x"
                />
              </a>
            </div>
          </div>
          <div className="linkListContainer">
            <div className="linkListWrapper cguWrapper">
              <Link href="/about-us">
                <a className="hvr-forward"> Qui sommes nous ?</a>
              </Link>
              <a
                href="/static/pdf/howto.pdf"
                target="_blank"
                className="hvr-forward"
              >
                Comment ça marche
              </a>
              <a
                href="https://medium.com/@togethrust"
                target="_blank"
                className="hvr-forward"
                rel="noopener noreferrer"
              >
                Notre blog
              </a>
              <a
                href="/static/pdf/cookies-policy.pdf"
                target="_blank"
                className="hvr-forward"
                rel="noopener noreferrer"
              >
                Politique de cookies
              </a>
              {!this.props.isLight && (
                <>
                  {/* <Link href="#">
                    <a className="hvr-forward">Services</a>
                  </Link> */}
                </>
              )}
            </div>
            {!this.props.isLight && (
              <div className="linkListWrapper newsWrapper">
                <a
                  href="/static/pdf/data-policy.pdf"
                  target="_blank"
                  className="hvr-forward"
                  rel="noopener noreferrer"
                >
                  Protection des données
                </a>
                <a
                  href="/static/pdf/cgu.pdf"
                  target="_blank"
                  className="hvr-forward"
                >
                  CGU TOGETHRUST
                </a>
                <a
                  href="https://www.api-money.com/cgu/CGU-API-money-EME.pdf"
                  target="_blank"
                  className="hvr-forward"
                >
                  CGU W-HA
                </a>
                <a
                  href="/static/pdf/faq.pdf"
                  target="_blank"
                  className="hvr-forward"
                >
                  FAQ
                </a>
                {/* <Link href="#">
                  <a className="hvr-forward">Actualités</a>
                </Link>
                <Link href="#">
                  <a className="hvr-forward">Ils parlent de nous</a>
                </Link>
                <Link href="#">
                  <a className="hvr-forward">Lexique</a>
                </Link>
                <Link href="#">
                  <a className="hvr-forward">Nous contacter</a>
                </Link> */}
              </div>
            )}
          </div>
          <div className="mobileViewCopyright">{`© Copyright Togethrust ${new Date().getFullYear()}`}</div>
        </footer>
      </StyledTgFooter>
    )
  }
}

TgFooter.propTypes = {}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TgFooter)
