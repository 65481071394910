import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

const Wrapper = styled.section`
  position: fixed;
  opacity: 0.9;
  z-index: 1306;
  background: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  left: 0px;
  bottom: 0px;
  justify-content: space-between;
  padding: 14px 135px;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08),
    0 -3px 4px 0 rgba(10, 31, 68, 0.1);

  .infos {
    font-size: 12px;
    margin: 0 20px 0 0;

    a {
      color: #0facf3;
      margin-left: 5px;
    }
  }

  .primaryButton {
    display: inline-flex;
    font-family: 'gotham-rounded-book', sans-serif;
    border-radius: 13px;
    border: solid 1px #0facf3;
    background-color: #0facf3;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.2px;
    white-space: nowrap;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    padding: 15px;
    flex-direction: column;

    .infos {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
`

const CookieBanner = () => {
  // Do not display cookies banner before fetching user choice from local storage
  const [show, setShow] = useState(false)

  useEffect(() => {
    // By default, show cookies banner to user
    if (!localStorage) setShow(true)
    else {
      const localShowCookies = localStorage.getItem('showCookies') || 'true'
      if (localShowCookies === 'true') setShow(true)
    }
  }, [show])

  function toggleShowCookies(bool) {
    setShow(false)
    // Store the user's choice through sessions
    localStorage.setItem('showCookies', bool.toString())
  }

  if (!show) return null

  return (
    <Wrapper>
      <span className="infos">
        Ce site utilise des cookies pour améliorer votre expérience. En
        poursuivant vous acceptez leur utilisation.
        <a
          href="/static/pdf/cookies-policy.pdf"
          target="_blank"
          className="hvr-forward"
          rel="noopener noreferrer"
        >
          En savoir plus
        </a>
      </span>
      <a
        onClick={() => toggleShowCookies(false)}
        id="cookie-button-accept"
        className="primaryButton hvr-forward"
      >
        J'ai compris
      </a>
    </Wrapper>
  )
}

export default CookieBanner
